@import "src/scss/module";

.Hero {
  border: none;
  border-radius: 0;
  background-color: transparent;
  position: relative;
  //z-index: -1;

  .bg_1 {
    position: absolute;
    right: 0;
    top: 0;
  }

  video {
    object-fit: cover;
  }

  :global {
    .card-img {
      border-radius: 0;
      min-height: 50vh;

      @include media-breakpoint-up(md) {
        min-height: 60vh;
      }

      @include media-breakpoint-down(sm) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

        &:after {
        content: "";
        display: block;
        position: absolute;
        pointer-events: none;
        top: 0;
        right: 0;
        bottom: -1px;
        left: 0;
        background-image: url("/assets/hero_foreground.webp");
        background-size: cover;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .card-img-overlay {
      border-radius: 0;
      text-wrap: balance;

      @include media-breakpoint-down(sm) {
        position: relative;
        top: initial;
        left: initial;
        width: auto;
        height: auto;
      }
    }
  }
}
